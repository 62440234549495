import BaseBean from "@/utils/BaseBean";
import Sortable, {MoveEvent, SortableEvent} from "sortablejs";
import { nextTick} from "vue";
// import _ from 'lodash';//现已卸载，需要重新安装才能使用
export interface ILayerDataObj {
    utilInst:LayerUtil
    refMap:Map<string,any>
    userMenus:any
    layerData:Array<any>
    layerDataBak:Array<any>
    curOperate:any
    dialogOpts:any
    folderOpts:any
    layerContentOuterOpts:any
    sortableInst:any
    newFolder:any
    searchContent:any
    iconShowTypeFlag:boolean
    layerAnimation:boolean
    defaultImg:string
    otherParams:any
}

export default class LayerUtil extends BaseBean{
    public dataObj:ILayerDataObj

    constructor(proxy:any,dataObj:ILayerDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    //为layer里面的节点添加Sortable
    public createLayerContentSortable():void{
        let utilInst:LayerUtil=this;
        let instance=new Sortable( document.querySelector('.layerContent') as HTMLElement, {
            group: 'contentItem',
            animation: 150,
            //指定可以拖动的元素
            // draggable:'.layerContentItem',
            //************* 拖动后的数据 **********
            dataIdAttr: "data-id",
            forceFallback: true,
            scroll: false,
            onStart: function (evt) {},
            onMove:function(evt:any){
                // console.log(evt.dragged.dataset.id,evt.related.dataset.id);
                //拖动的时候，只要两个dom重合，那么就会触发onMove事件，onMove如果返回false，
                //不会改变两个dom的顺序，返回true则更改试图且会改变两个dom的顺序。
                //这里故意返回false，目的就是不更新试图，否则拖动的时候不停更改试图上的dom顺序，
                //我在onEnd那里才更改试图顺序，也就是停止拖动才更改，但是onEnd又拿不到结束节点的id，也就是说在onEnd那里找不到结束节点，
                //于是在onMove这里不停记录结束节点，需要注意的是在确定合并或者移动之后，要把记录的信息给清除掉，否则再次拖动的时候，明明没有重合两个dom，
                //就会把上次记录的结束节点给带出来。
                //注意：从dataset取出来的属性都是小写，其值都是字符串
                utilInst.dataObj.curOperate={dragged:evt.dragged,related:evt.related};
                return false;
            },
            onEnd:function(evt:any){
                // console.log(evt.item.dataset.id);//移动的项（from）
                //好恼火，在这里拿不到结束节点，只能在onMove那里去记录了
                //如果本次拖动结束，但是没有和任何其它节点重合，则不弹出合并移动弹出框，否则弹出让用户选择操作类别
                if(utilInst.dataObj.curOperate && utilInst.dataObj.curOperate.dragged && utilInst.dataObj.curOperate.related){
                    utilInst.dataObj.dialogOpts.moveVisible=true;
                    //重置信息(否则会把上次的信息给留下)
                    utilInst.dataObj.dialogOpts.moveType=0;
                    utilInst.dataObj.dialogOpts.folderName='';
                }
            }
        })
        this.dataObj.sortableInst.layerContentSortable=instance;
    }

    //打开一个folder，为folder里面的叶子节点添加sortable事件
    public createFolderContentSortable():void{
        let utilInst:LayerUtil=this;
        nextTick(()=>{
            let instance=new Sortable(document.querySelector('.folderContent') as HTMLElement, {
                group: 'contentItem',
                animation: 150,
                //指定可以拖动的元素
                // draggable:'.folderContentItem',
                //************* 拖动后的数据 **********
                dataIdAttr: "data-id",
                forceFallback: true,
                // sort: false,
                onStart: function (evt) {
                    utilInst.dataObj.folderOpts.borderCls='#bdc3c7 1px dashed';
                },
                onMove:function(evt:MoveEvent,originalEvent:Event){
                    // console.log(evt.draggedRect);
                    // console.log('clientX:'+originalEvent.clientX,'clientY:'+originalEvent.clientY);
                    return true;
                },
                //拖动结束
                onEnd: function (evt:SortableEvent) {
                    utilInst.dataObj.folderOpts.borderCls='none';
                    //移动项的id
                    let moveItemId=evt.item.dataset.id;
                    //移动完成之后，主页layer中数据id集合（只有id，没有其它信息）
                    let layerArrId = utilInst.dataObj.sortableInst.layerContentSortable.toArray();
                    //查找移动项是否在主页layer中存在，存在则说明移到主页去了，不存在则说明只是在folder中改变顺序而已，没有移出去
                    let moveItemLayerIndex=layerArrId.findIndex((id:any)=>{
                        return id==moveItemId
                    })
                    //如果移到主页去了，则需要从layerData正在操作的文件夹信息中删除移动项，同时向layerData中指定位置添加移动项
                    //相当于把移动项从某个children下面提升到最外层来
                    //如果没有移动到外层去，那么需要改变layerData中的顺序
                    if(moveItemLayerIndex!=-1){//1、再主页找到了，说明移出去了
                        let moveItem=utilInst.findFolderItemById(moveItemId);//根据id找到正在操作项的完整信息
                        moveItem.parentId='-';//由于要移除到主页了，所以把它的父id变为’-‘
                        utilInst.dataObj.layerData.splice((moveItemLayerIndex+1), 0, moveItem);//向主页layerData指定位置添加移除的项
                        let folderItem=utilInst.dataObj.folderOpts.folderItem;//正在操作的文件夹
                        let layerData=utilInst.dataObj.layerData;
                        for(let i=0;i<layerData.length;i++){//循环layerData，找到对应文件夹，把移动项删除
                            if(layerData[i]['id']==folderItem.id){//找到layerData中正在操作的文件夹
                                let index=layerData[i].children.findIndex((item:any)=>{//找到正在操作项在文件夹中的位置
                                    return item.id==moveItem.id;
                                })
                                if(index!=-1){
                                    layerData[i].children.splice(index, 1);//从文件夹中删除正在操作的项
                                    if(layerData[i].children.length==0)layerData.splice(i, 1);//如果文件夹为空，则从layerData中删除文件夹
                                }
                                break;
                            }
                        }
                    }else{//2、主页没有找到，说明只是改变了顺序，更新layerData中相应顺序
                        let oldIndex=evt.oldIndex as number;//原来的位置
                        let newIndex=evt.newIndex as number;//新的位置
                        let folderItem=utilInst.dataObj.folderOpts.folderItem;//正在操作的文件夹
                        let layerData=utilInst.dataObj.layerData;
                        let index=layerData.findIndex((item:any)=>{
                            return item.id==folderItem.id;
                        })
                        if(index!=-1)
                        [utilInst.dataObj.layerData[index]['children'][newIndex],utilInst.dataObj.layerData[index]['children'][oldIndex]]
                            =[utilInst.dataObj.layerData[index]['children'][oldIndex],utilInst.dataObj.layerData[index]['children'][newIndex]];
                    }
                    //获取拖动后文件夹中剩余项ID集合
                    let folderArrId = instance.toArray();
                    if(folderArrId.length==0){//如果拖动完成之后，文件夹为空了，则直接显示主页，上面已经把文件夹给删除了
                        utilInst.dataObj.folderOpts.visibility='hidden';
                        utilInst.dataObj.layerContentOuterOpts.opacity=1;
                    }
                    utilInst.saveUserLayout();
                    utilInst.dataObj.layerDataBak=utilInst.dataObj.layerData;
                }
            })
            this.dataObj.sortableInst.folderContentSortable=instance;
        })
    }

    //初始化layerData
    public initData(flag:boolean):void{
        this.dataObj.layerData.length=0;
        let loading;
        if(flag)loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        //根据用户菜单构造layerData
        let userLayerData=sessionStorage.getItem('userLayerData');//用户之前自己构造过布局
        if(userLayerData){
            this.dataObj.layerData=JSON.parse(this.utils.UtilPub.decrypt(userLayerData));
        }else{//用户从来没有构造过布局，则根据用户拥有的菜单才构建layer
            if(this.dataObj.userMenus){
                let layerShowType:any=sessionStorage.getItem('layerShowType');//展示末级(1)还是顶级(0)
                if(!layerShowType)layerShowType='0';
                this.dataObj.userMenus.forEach((item:any)=>{
                    if(layerShowType=='0'){//顶级
                        let layerItem:any={id:item.id,parentId:'-',title:item.meta.title,icon:item.meta.icon};
                        try {
                            if(item.meta.pic){
                                import('@/assets/layer/itemPic/'+item.meta.pic).then((module) => {
                                    layerItem.pic=module.default;
                                    if(item.path)layerItem.path=item.path;//如果某个菜单只有一级，那么它也是顶级菜单且有path可以点击
                                    this.dataObj.layerData.push(layerItem);//这句话要是放到外面，会造成页面按照layerData初始化好了，但是layerItem的pic才构造好，就有错了
                                });
                            }
                        }catch (e) {
                            this.proxy.$message({message:this.proxy.$t('layer.picNotExist',{picName:item.meta.pic}),type:'error'});
                        }

                    }else{//末级菜单
                        this.dataObj.utilInst.buildLayerData(item);
                    }
                })
            }
        }
        this.dataObj.layerDataBak=this.dataObj.layerData;
        if(flag)loading.close();
    }

    //根据id找到弹出folder中的某项完整信息
    public findFolderItemById(id:any):any{
        return this.dataObj.folderOpts.folderChild.find((item:any)=>item.id==id)
    }
    //根据id找到主页layerData中的某项完整信息
    public findLayerDataItemById(id:any):any{
        return this.dataObj.layerData.find((item:any)=>item.id==id)
    }
    //根据id找到它在主页layerData中的索引位置信息
    public findIndex(id:any):number{
        return this.dataObj.layerData.findIndex(item=>item.id==id)
    }
    //从layerData中删除指定项
    public deleteItemFromLayerData(id:any):void{
        let index=this.dataObj.layerData.findIndex(item=>item.id==id)
        if(index!=-1)this.dataObj.layerData.splice(index, 1);
        this.dataObj.layerData=this.dataObj.layerDataBak;
    }
    //设置layerData里面某项的title
    public cfgItemTitle(id:any,title:string):void{
        let index=this.dataObj.layerData.findIndex(item=>item.id==id)
        if(index!=-1)this.dataObj.layerData[index]['title']=title;
        this.dataObj.layerData=this.dataObj.layerDataBak;
    }
    //如果是最末级菜单且是外部链接，则点击它的时候，用window.open来打开
    public toPage(page:string,prop:number,type:number):void{
        const userToken = sessionStorage.getItem(this.utils.Const.jfAccessToken);
        if(1==type){
            window.open(page+'?token='+userToken);
        }else{
            window.open(page);
        }
    }
    //根据menuId找到menu的完整信息
    public findMenuAllInfo(menu:any,id:any):any{
        if(menu.id==id){
            return menu;
        }else{
            if(menu.children){
                for(let i=0;i<menu.children.length;i++){
                    let v=this.findMenuAllInfo(menu.children[i],id);
                    if(v)return v;
                }
            }
        }
    }
    //构造layerData(全部是叶子节点,当layer只展示叶子节点的时候用)
    public buildLayerData(menu:any):void{
        if(!menu.children){
            if(menu.meta.show==0){
                if(menu.meta.pic){
                    import('@/assets/layer/itemPic/'+menu.meta.pic).then((img) => {
                        let layerItem:any={id:menu.id,parentId:'-',title:menu.meta.title,icon:menu.meta.icon,path:menu.path};
                        layerItem.pic=img.default;
                        this.dataObj.layerData.push(layerItem);
                    });
                }
            }
        }else{
            menu.children.forEach((item:any)=>this.buildLayerData(item))
        }
    }
    //存储用户布局
    public async saveUserLayout():Promise<void>{
        let res=await this.utils.Api.saveLayerLayout({layerData:this.dataObj.layerData});
        if(res.result){
            this.proxy.$message({type:'success',message:this.proxy.$t('tools.success')});
            sessionStorage.setItem('userLayerData',this.utils.UtilPub.encrypt(JSON.stringify(this.dataObj.layerData)));
        }else{
            this.utils.Tools.error();
        }
    }
    //layer文件夹名称双击事件
    public async editFoldName(item:any):Promise<void>{
        for(let i=0;i<this.dataObj.layerData.length;i++){
            if(this.dataObj.layerData[i].id==item.id){
                this.dataObj.layerData[i].title=this.dataObj.newFolder.newFoldName;
                this.dataObj.layerData[i].editFolderName=false;
                break;
            }
        }
        await this.saveUserLayout();
        this.dataObj.layerData=this.dataObj.layerDataBak;
    }
    //点击某个操作项
    public async doItemClick(item:any,flag:boolean):Promise<void>{
        if(flag){//叶子节点
            //根据点击的layerItem查找对应的菜单完整信息
            let itemMenuInfo;
            for(let i=0;i<this.dataObj.userMenus.length;i++){
                let v=this.dataObj.utilInst.findMenuAllInfo(this.dataObj.userMenus[i],item.id);
                if(v)itemMenuInfo=v;
            }
            if(itemMenuInfo){
                if(itemMenuInfo.children){//如果菜单有子菜单，说明现在点击的图标不是末级菜单，那么就直接跳转到主页，tab只显示welcome，slidebar只显示该菜单的所有子菜单
                    sessionStorage.setItem("slideUserMenus", this.utils.UtilPub.encrypt(JSON.stringify(itemMenuInfo.children))); //设置菜单字符串，方便在slidBar那里取该用户的菜单
                    sessionStorage.setItem("accessModule", this.utils.UtilPub.encrypt(JSON.stringify(itemMenuInfo)));
                    //关闭所有tabs，否则通过点击浏览器后退按钮会把之前打开的菜单给呈现出来
                    //你可能会说在mainHelper的onMounted里面已经有关闭所有tab的操作，这里岂不是多余了吗？不会的，
                    //因为当你在layer点击一个末级菜单进入main之后，第一次会执行mainHelper的onMounted方法，这个时候你点击浏览器的后退按钮回到layer，
                    //再点击另外一个末级菜单进入main，就不会执行mainHelper的onMounted方法了，那么就不会把上次打开的tab给关闭掉，因此需要在layer点击这里清除掉已打开的tab
                    //        后来改为：不清除已打开的tab，记录每次从layer进入main的模块，因为实际工作的时候，可能经常操作的两个菜单在不同的模块中，如果不在top那里记录已打开
                    //                的模块，那么需要从main切换到layer，然后再打开另外的模块，这个时候再次进入main，又被清除了已打开的tabs，所以操作性不好，于是改为现在这样
                    // await store.dispatch("closeAllVisitedTabsView");
                    await this.proxy.$router.push('/');
                }else if(itemMenuInfo.path && itemMenuInfo.meta.show==0){//如果点击的是末级菜单，且菜单要显示（show=0）
                    if(itemMenuInfo.meta.prop==1){//如果是打开外部链接
                        this.dataObj.utilInst.toPage(itemMenuInfo.page,itemMenuInfo.meta.prop,itemMenuInfo.meta.type);
                    }else{//跳转到main中的二级路由，tab显示对应的路由，slidebar只显示该末级菜单
                        sessionStorage.setItem("slideUserMenus", this.utils.UtilPub.encrypt(JSON.stringify([itemMenuInfo]))); //设置菜单字符串，方便在slidBar那里取该用户的菜单
                        sessionStorage.setItem('refreshRouteInfo',JSON.stringify({title:itemMenuInfo.meta.title,path:itemMenuInfo.path}));
                        sessionStorage.setItem("accessModule", this.utils.UtilPub.encrypt(JSON.stringify(itemMenuInfo)));
                        //关闭所有tabs，否则通过点击浏览器后退按钮会把之前打开的菜单给呈现出来
                        //参见上方的描述
                        // await store.dispatch("closeAllVisitedTabsView");
                        await this.proxy.$router.push(itemMenuInfo.path);
                    }
                }
            }
        }else{//文件夹
            this.dataObj.folderOpts.folderItem=item;
            this.dataObj.folderOpts.folderChild=item.children;
            this.dataObj.folderOpts.visibility='visible';
            this.dataObj.layerContentOuterOpts.opacity=0;
            this.dataObj.utilInst.createFolderContentSortable();
        }
    }
//---------------------------------------------搜索事件--------------------------------
    public searchChange(){
        // let copyLayerData:any=_.cloneDeep(this.dataObj.layerData);//深度克隆
        if(this.dataObj.searchContent){
            let result=this.searchFilter(this.dataObj.layerDataBak,this.dataObj.searchContent);
            this.dataObj.layerData=result;
        }else{
            this.dataObj.layerData=this.dataObj.layerDataBak;
        }
    }
    // 搜索框过滤
    public searchFilter(copyLayerData:any, queryString:any):any {
        let delIdArr:any=[];
        for(let i=0;i<copyLayerData.length;i++){
            if(copyLayerData[i].children){
                copyLayerData[i].children=copyLayerData[i].children.filter((item:any)=>item['title'].indexOf(queryString)!=-1);
            }else{
                if(copyLayerData[i]['title'].indexOf(queryString)==-1)delIdArr.push(copyLayerData[i]['id']);
            }
        }
        let newArr=copyLayerData.filter((item:any,index:number,arr:any)=>{
            if(item.children){
                return item.children.length>0;
            }else{
                return delIdArr.indexOf(item['id'])==-1;
            }
        });
        return newArr;
    }
}